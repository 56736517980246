import React from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { useIsAuthenticated } from 'lib/hooks/useIsAuthenticated';
import styles from './indexPage.module.css';
import Button from 'components/Button';
import Link from 'components/Link';
import SEO from 'components/SEO';

interface LayoutProps {
  title: string;
  children: React.ReactNode;
}

export default function Index() {
  const isAuthenticated = useIsAuthenticated();
  return (
    <>
      <SEO
        title="SHADE Program, self-help for alcohol/other drug use and depression"
        description="SHADE is a Self-Help program for people with Alcohol or other drug use problems and Depression"
      />
      <main className={styles['main']}>
        <div className={styles['Layout']}>
          <Header showMenu={isAuthenticated} />
          <div className={styles['layout--internal']}>
            <section className={styles['program-section']}>
              <div className={styles['program-section__description']}>
                <h1 className="a_fade--up">
                  What is the <span className={styles['color-yellow']}>shade</span> program?
                </h1>
                <p className="a_fade--left">
                  <span className={styles['uppercase']}>Shade</span> is a <span className={styles['color-yellow']}>S</span>elf-
                  <span className={styles['color-yellow']}>H</span>elp program for people with <span className={styles['color-yellow']}>A</span>lcohol or other
                  drug use problems and <span className={styles['color-yellow']}>D</span>
                  epression. It is designed to help you take practical steps towards helping improve your life.
                </p>
                {/* <Button className={styles['action']} component="link" style="rounded" link={{ href: '/login' }}>
                  Get started
                </Button> */}
              </div>
              <div className={`${styles['program-section__image']} a_fade--in`}>
                <svg aria-hidden="true" viewBox="60 0 40 55.444">
                  <g transform="translate(61.656 3.35)">
                    <path
                      fill="#f7b06b"
                      d="M347.238,176.487a1.247,1.247,0,0,1-1.247-1.247v-4.882a1.247,1.247,0,0,1,2.493,0v4.882A1.247,1.247,0,0,1,347.238,176.487Z"
                      transform="translate(-345.991 -169.111)"
                    />
                    <g transform="translate(13.832 4.36)">
                      <path
                        fill="#f7b06b"
                        d="M412.138,196.285a1.247,1.247,0,0,1-1.078-1.87l2.439-4.225a1.247,1.247,0,0,1,2.159,1.246l-2.439,4.225A1.246,1.246,0,0,1,412.138,196.285Z"
                        transform="translate(-410.893 -189.568)"
                      />
                    </g>
                    <g transform="translate(24.252 16.888)">
                      <path
                        fill="#f7b06b"
                        d="M461.034,253.191a1.247,1.247,0,0,1-.6-2.34l4.277-2.347a1.247,1.247,0,1,1,1.2,2.186l-4.277,2.347A1.24,1.24,0,0,1,461.034,253.191Z"
                        transform="translate(-459.786 -248.351)"
                      />
                    </g>
                    <g transform="translate(27.632 33.747)">
                      <path
                        fill="#f7b06b"
                        d="M481.771,330.161l-.055,0-4.879-.213a1.246,1.246,0,0,1-1.191-1.3,1.233,1.233,0,0,1,1.3-1.191l4.879.213a1.247,1.247,0,0,1-.053,2.492Z"
                        transform="translate(-475.644 -327.454)"
                      />
                    </g>
                    <g transform="translate(23.557 47.042)">
                      <path
                        fill="#f7b06b"
                        d="M461.927,394.891a1.24,1.24,0,0,1-.652-.185l-4.156-2.558a1.246,1.246,0,0,1,1.307-2.123l4.156,2.558a1.246,1.246,0,0,1-.655,2.308Z"
                        transform="translate(-456.526 -389.84)"
                      />
                    </g>
                  </g>
                  <g transform="translate(62.422 15.583)">
                    <g transform="translate(0)">
                      <path
                        className="a"
                        fill="#f7b06b"
                        d="M350,227.838l3.493,12.214.01.036.01.036A31.311,31.311,0,0,0,365.455,257.4l2.894,2.08a21.553,21.553,0,0,0-18.281-32.974c-.161,0-.32.009-.48.012C349.735,226.956,349.876,227.395,350,227.838Z"
                        transform="translate(-349.587 -226.507)"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </section>
            <section className={`${styles['who-section']} a_fade--in`}>
              <div className={styles['who-section__description']}>
                <h1>
                  <span className={styles['color-aqua']}>Who</span> is it for?
                </h1>
                <p>
                  <span className={styles['uppercase']}>Shade</span> has been designed to help people experiencing both depressive symptoms and who are drinking
                  alcohol or using other drugs.
                </p>
                <p>It has been trialled in a number of clinical and research settings with over 350 Australians.</p>
              </div>
            </section>
            <section className={`${styles['offer-section']} a_fade--in`}>
              <div className={styles['offer-section__description']}>
                <h1>
                  What does <span className={styles['color-yellow']}>shade</span> offer?
                </h1>
                <p>
                  If you decide to use the <span className={styles['uppercase']}>Shade</span> program, you will be taken through a process of working out which
                  parts of your current lifestyle might be distressing, worrying or upsetting you.
                </p>
                <p>
                  You can then access a range of tips, information and strategies to help change these particular parts, and develop a lifestyle that is more in
                  line with your goals, wants and needs.
                </p>
              </div>
              <div className={styles['offer-section__image']}>
                <svg aria-hidden="true" viewBox="0 0 78 55.444">
                  <g fill="#fff">
                    <g transform="translate(0 17.868)">
                      <path
                        fill="#d4f2f5"
                        d="M90.519,256.748l-2.247-7.855A16.1,16.1,0,1,0,72.8,269.435H99.286A22.932,22.932,0,0,1,90.519,256.748Z"
                        transform="translate(-56.693 -237.231)"
                      />
                    </g>
                    <g transform="translate(12.13)">
                      <path
                        fill="#68d3d9"
                        d="M117.58,166.83a20.335,20.335,0,0,1,12.464,4.218,20.684,20.684,0,0,1,7.271,10.654l2.246,7.855.01.036.01.036a18.553,18.553,0,0,0,7.074,10.238l5,3.6h25.525a35.657,35.657,0,0,1-13.632-19.727l-3.493-12.214a25.035,25.035,0,0,0-46.451-4.3A20.542,20.542,0,0,1,117.58,166.83Z"
                        transform="translate(-113.608 -153.39)"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </section>
            <section className={`${styles['ready-section']} a_fade--in`}>
              <div className={styles['ready-section__description']}>
                <h1>Ready to get started?</h1>
                <p>
                  The portal is able to provide you with the login details required to get started on the program. If you would like more information{' '}
                  <Link legacyBehavior href="/contact">
                    contact us
                  </Link>
                  .
                </p>
                <Button className={styles['action']} component="link" style="rounded" link={{ href: '/login' }}>
                  Get started
                </Button>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
}
